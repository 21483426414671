const nameToUrls = {
  colaboratorSync: "api/colaborators/",
  colaboratorEdit: "api/colaborators-m/",
  colaboratorRemove: "api/colaborators-b/",
  domains: "api/domains/",
  errorReporting: "api/logs/",
  uoFolders: "api/uoFolders/",
  uo: "api/uo/",
  audit:"api/audits",
  positions: 'api/positions/',
  areas: 'api/areas/',
};
export default nameToUrls;
