import { fetch } from "../utils/dataAccess";
import nameToUrls from "./nameToUrls";
import _ from "lodash";

const initialState = {
  error: null,
  loading: false,
  retrieved: null,
  edit: false,
  selected: true,
};
export const makeModel = (name) => {
  const data = {
    name: name,
    state: {
      create: initialState,
      list: initialState,
      del: initialState,
      show: initialState,
      update: initialState,
      options: initialState,
      showAreas: initialState,
      filter: "",
    },
    reducers: {
      setCreateError(state, error) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, error } }
        );
      },
      setCreateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, loading } }
        );
      },
      setCreated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, retrieved } }
        );
      },
      createReset(state) {
        return Object.assign({}, { ...state, create: initialState });
      },
      setListError(state, error) {
        return Object.assign({}, { ...state, list: { ...state.list, error } });
      },
      setListLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, loading } }
        );
      },
      setListed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, retrieved } }
        );
      },
      listReset(state) {
        return Object.assign({}, { ...state, list: initialState });
      },
      setDeleteError(state, error) {
        return Object.assign({}, { ...state, del: { ...state.del, error } });
      },
      setDeleteLoading(state, loading) {
        return Object.assign({}, { ...state, del: { ...state.del, loading } });
      },
      setDeleted(state, retrieved) {
        return Object.assign(
          {},
          { ...state, del: { ...state.del, retrieved } }
        );
      },
      deleteReset(state) {
        return Object.assign({}, { ...state, del: initialState });
      },
      setShowError(state, error) {
        return Object.assign({}, { ...state, show: { ...state.show, error } });
      },
      setShowLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, loading } }
        );
      },
      setShowed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, retrieved } }
        );
      },
      showReset(state) {
        return Object.assign({}, { ...state, show: initialState });
      },
      setUpdateError(state, error) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, error } }
        );
      },
      setUpdateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, loading } }
        );
      },
      setUpdated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, retrieved } }
        );
      },
      setListEdit(state, edit) {
        return Object.assign({}, { ...state, list: { ...state.list, edit } });
      },
      setUpdateEdit(state, edit) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, edit } }
        );
      },
      setListSelected(state, selected) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, selected } }
        );
      },
      updateReset(state) {
        return Object.assign({}, { ...state, update: initialState });
      },
      setOptionsError(state, error) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, error } }
        );
      },
      setOptionsLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, loading } }
        );
      },
      setOptions(state, retrieved) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, retrieved } }
        );
      },
      optionsReset(state) {
        return Object.assign({}, { ...state, options: initialState });
      },

      setFilter(state, filter) {
        return Object.assign({}, { ...state, filter: filter });
      },
    },
    effects: (dispatch) => ({
      async list(payload, state) {
        return new Promise((resolve, reject) => {
          this.setListLoading(true);
          this.setListError(null);

          let url = nameToUrls[name];

          if (payload !== undefined && payload.url !== undefined)
            url += payload.url;

          fetch(url)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setListLoading(false);
              this.setListed(retrieved);
              resolve(retrieved);
              if (payload.url !== "Transferido") {
                this.setListEdit(true);
              } else {
                this.setListEdit(false);
              }
            })
            .catch((e) => {
              this.setListLoading(false);
              this.setListError(e.message);
              reject(e.message);
            });
        });
      },
      options(payload, state) {
        return new Promise((resolve, reject) => {
          this.setOptionsLoading(true);
          this.setOptionsError(null);
          const endpoint = payload?.endpoint ? payload.endpoint : "options";
          //
          fetch(nameToUrls[name] + endpoint)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setOptionsLoading(false);
              const sortedArray = _.sortBy(retrieved, ["label"]);
              this.setOptions(sortedArray);
              resolve(sortedArray);
            })
            .catch((e) => {
              this.setOptionsLoading(false);
              this.setOptionsError(e.message);
              reject();
            });
        });
      },
      show(payload, state) {
        if (payload?.row?.pathUo) {
          payload.row.uo = payload?.row?.pathUo?.split("/")[0];
          payload.row.uoFolders = payload?.row?.pathUo?.split("/")[1];
        }
        if (payload?.row?.correoElectronico?.includes("@")) {
          return new Promise((resolve, reject) => {
            payload.row.cuenta = payload?.row?.correoElectronico?.split("@")[0];
            payload.row.dominio =
              payload?.row?.correoElectronico?.split("@")[1];
            this.setShowed(payload.row);
            resolve(payload.row);
          });
        } else {
          return new Promise((resolve, reject) => {
            payload.row.cuenta = "";
            payload.row.dominio = "";
            this.setShowed(payload.row);
            resolve(payload.row);
          });
        }
      },
      onlyShow(payload, state) {
        return new Promise((resolve, reject) => {
          this.setShowed(payload.row);
          resolve(payload.row);
        });
      },
      confirm(list, state) {
        const index = list?.list.findIndex(
          (todo) => todo.idPersona === list?.row?.idPersona
        );

        const newArray = [...(state.colaboratorSync?.list?.retrieved ?? [])];
        if (newArray.length > index) {
          newArray[index].correoElectronico =
            list?.row?.cuenta + "@" + list?.row?.dominio;
          newArray[index].pathUo = list?.row?.uo + "/" + list?.row?.uoFolders;
          newArray[index].uo = list?.row?.uo;
          newArray[index].uoFolders = list?.row?.uoFolders;
          newArray[index].uoId = list?.row?.uoId;
          newArray[index].sincroAD = list?.row?.sincroAD;
          newArray[index].sincroGSuite = list?.row?.sincroGSuite;
          newArray[index].contacto = list?.row?.contacto;
          newArray[index].cuentaAD = list?.row?.cuentaAD;
          newArray[index].correoElectronicoJefe = list?.row?.correoElectronicoJefe;
        }
        return {
          ...state,
          colaboratorSync: {
            ...state.colaboratorSync,
            list: { ...state.colaboratorSync?.list, retrieved: newArray },
          },
        };
      },
      /**Redux para baja */
      remove(list, state) {
        const index = list?.list.findIndex(
          (todo) => todo.idPersona === list?.row?.idPersona
        );
        const newArray = [...(state.colaboratorRemove?.list?.retrieved ?? [])];
        if (newArray.length > index) {
          newArray[index].sincroAD = list?.row?.sincroAD;
          newArray[index].sincroGSuite = list?.row?.sincroGSuite;
          newArray[index].suspendido = list?.row?.suspendido;
          newArray[index].correoElectronicoJefe = list?.row?.correoElectronicoJefe;
          newArray[index].correoElectronico = list?.row?.cuenta + "@" + list?.row?.dominio;
          newArray[index].envioEmailBaja = list?.row?.envioEmailBaja;
        }
        return {
          ...state,
          colaboratorRemove: {
            ...state.colaboratorRemove,
            list: { ...state.colaboratorRemove?.list, retrieved: newArray },
          },
        };
      },
      checkRemove(list, state) {
        let newArray;
        let founded;
        newArray = [...(state.colaboratorRemove?.list?.retrieved ?? [])];
        for (let i = 0; i < list.list.length; i++) {
          founded = false;
          list?.row.selectedRows.map((elem) => {
            if (elem.idPersona === list.list[i].idPersona) {
              newArray[i].aValidar = true;
              founded = true;
            }
          });
          if (!founded) {
            newArray[i].aValidar = false;
          }
        }
        if (list.row.selectedRows.length === 0) {
          this.setListSelected(true);
        } else {
          this.setListSelected(false);
        }
        return {
          ...state,
          colaboratorRemove: {
            ...state.colaboratorRemove,
            list: { ...state.colaboratorRemove?.list, retrieved: newArray },
          },
        };
      },

      //Modificacion de colaborador
      edit(list, state) {
        const index = list?.list.findIndex(
          (todo) => todo.idPersona === list?.row?.idPersona
        );
        const newArray = [...(state.colaboratorEdit?.list?.retrieved ?? [])];
        
        if (newArray.length > index) {
          newArray[index].correoElectronico =  list?.row?.cuenta + "@" + list?.row?.dominio;
          newArray[index].pathUo = list?.row?.uo + "/" + list?.row?.uoFolders;
          newArray[index].observations = list?.row?.observations;
          newArray[index].uo = list?.row?.uo;
          newArray[index].uoFolders = list?.row?.uoFolders;
          newArray[index].uoId = list?.row?.uoId;
          newArray[index].entidadLegal = list?.row?.entidadLegal;
          newArray[index].nombreOrgExterna = list?.row?.nombreOrgExterna;
          newArray[index].sincroAD = list?.row?.sincroAD;
          newArray[index].sincroGSuite = list?.row?.sincroGSuite;
          newArray[index].suspendido = list?.row?.suspendido;
          newArray[index].contacto = list?.row?.contacto;
          newArray[index].cuentaAD = list?.row?.cuentaAD;
          newArray[index].correoElectronicoJefe = list?.row?.correoElectronicoJefe;
        }
        return {
          ...state,
          colaboratorEdit: {
            ...state.colaboratorEdit,
            list: { ...state.colaboratorEdit?.list, retrieved: newArray },
          },
        };
      },
      checkEdit(list, state) {
        let newArray;
        let founded;
        newArray = [...(state.colaboratorEdit?.list?.retrieved ?? [])];
        for (let i = 0; i < list.list.length; i++) {
          founded = false;
          list?.row.selectedRows.map((elem) => {
            if (elem.idPersona === list.list[i].idPersona) {
              newArray[i].aValidar = true;
              founded = true;
            }
          });
          if (!founded) {
            newArray[i].aValidar = false;
          }
        }
        if (list.row.selectedRows.length === 0) {
          this.setListSelected(true);
        } else {
          this.setListSelected(false);
        }
        return {
          ...state,
          colaboratorEdit: {
            ...state.colaboratorEdit,
            list: { ...state.colaboratorEdit?.list, retrieved: newArray },
          },
        };
      },
      check(list, state) {
        let newArray;
        let founded;
        newArray = [...(state.colaboratorSync?.list?.retrieved ?? [])];
        for (let i = 0; i < list.list.length; i++) {
          founded = false;
          list?.row.selectedRows.map((elem) => {
            if (elem.idPersona === list.list[i].idPersona) {
              newArray[i].aValidar = true;
              founded = true;
            }
          });
          if (!founded) {
            newArray[i].aValidar = false;
          }
        }
        if (list.row.selectedRows.length === 0) {
          this.setListSelected(true);
        } else {
          this.setListSelected(false);
        }
        return {
          ...state,
          colaboratorSync: {
            ...state.colaboratorSync,
            list: { ...state.colaboratorSync?.list, retrieved: newArray },
          },
        };
      },
      create(payload, state) {
        return new Promise((resolve, reject) => {
          this.setCreateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setCreateError(null);

          fetch(nameToUrls[name], {
            method: "POST",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setCreateLoading(false);
              response.json().then((retrieved) => {
                this.setCreated(retrieved);
                resolve(retrieved);
              });
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
            })
            .catch((e) => {
              this.setCreateLoading(false);
              this.setCreateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
              reject(e.message);
            });
        });
      },
      update(payload, state) {
        return new Promise((resolve, reject) => {
          this.setUpdateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setUpdateError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "PUT",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setUpdateLoading(false);
              response
                .json()
                .then((retrieved) => {
                  this.setUpdated(retrieved);
                  resolve(retrieved);
                })
                .catch(() =>
                  this.setUpdated({ ...payload.item, item: "no item" })
                );
            })

            .catch((e) => {
              this.setUpdateLoading(false);
              this.setUpdateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }

              reject(e.message);
            });
        });
      },
      async delete(payload, state) {
        return new Promise((resolve, reject) => {
          this.setDeleteLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);
           
          this.setDeleteError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "DELETE",
          })
            .then((response) => {
              this.setDeleteLoading(false);
              this.setDeleted(payload.item);
              resolve(payload.item);
            })
            .catch((e) => {
              this.setDeleteLoading(false);
              this.setDeleteError(e.message);
              reject(e.message);
            });
        });
      },
      optionsInput(id, payload, url, state) {
        return new Promise((resolve, reject) => {
          this.setOptionsLoading(true);
          this.setOptionsError(null);
          const endpoint = "options";
          fetch(url.preUrl + id + url.postUrl + endpoint)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setOptionsLoading(false);
              const sortedArray = _.sortBy(retrieved, ["label"]);
              this.setOptions(sortedArray);
              resolve(sortedArray);
            })
            .catch((e) => {
              this.setOptionsLoading(false);
              this.setOptionsError(e.message);
              reject();
            });
        });
      },
      //Areas Y Puestos
      async deleteAP(payload, state) {
        return new Promise((resolve, reject) => {
          this.setDeleteLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);
           
          this.setDeleteError(null);

          fetch(nameToUrls[name] + payload.item.meta4Id, {
            method: "DELETE",
          })
            .then((response) => {
              this.setDeleteLoading(false);
              this.setDeleted(payload.item);
              resolve(payload.item);
            })
            .catch((e) => {
              this.setDeleteLoading(false);
              this.setDeleteError(e.message);
              reject(e.message);
            });
        });
      },
      updateAP(payload, state) {
        return new Promise((resolve, reject) => {
          this.setUpdateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setUpdateError(null);
          
          fetch(nameToUrls[name] + payload.item.meta4Id, {
            method: "PUT",
            body: JSON.stringify(payload.item),
          })
            .then((response) => {
              this.setUpdateLoading(false);
              response
                .json()
                .then((retrieved) => {
                  this.setUpdated(retrieved);
                  resolve(retrieved);
                })
                .catch(() =>
                  this.setUpdated({ ...payload.item, item: "no item" })
                );
            })

            .catch((e) => {
              this.setUpdateLoading(false);
              this.setUpdateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }

              reject(e.message);
            });
        });
      },
      createAP(payload, state) {
        return new Promise((resolve, reject) => {
          this.setCreateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setCreateError(null);

          fetch(nameToUrls[name], {
            method: "POST",
            body: JSON.stringify(payload.item),
          })
            .then((response) => {
              this.setCreateLoading(false);
              response.json().then((retrieved) => {
                this.setCreated(retrieved);
                resolve(retrieved);
              });
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
            })
            .catch((e) => {
              this.setCreateLoading(false);
              this.setCreateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
              reject(e.message);
            });
        });
      }
    }),
  };
  return data;
};
