import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const Jobs = (props) => {
  const [visible, setVisible] = React.useState(true);
  // let interval=null

  const [interval, setInt] = React.useState(null);
  useEffect(() => {
    setInt(
      setInterval(() => props.updateActualJob(props.actualJob.jobId), 2000)
    );
    // debugger;
    return () => clearInterval(interval);
  }, [props.actualJob.jobId]);

  useEffect(() => {
    // debugger;
    if (props.actualJob.JOB_ESTADO !== 22) {
      clearInterval(interval);
      setVisible(true);
    }
  }, [props.actualJob]);

  if (!props.actualJob?.jobId) return <></>;

  if (!visible) {
    return <></>;
  }

  const close = (
    <button
      type="button"
      onClick={() => {
        setVisible(false);
      }}
      className="ml-1 close"
    >
      <span>×</span>
    </button>
  );

  return (
    <Toast className="job-toast ">
      <ToastHeader className="bg-primary" close={close}>
        Actualizando Valor
      </ToastHeader>
      <ToastBody>
        {props.actualJob?.JOB_ESTADO === 22 ? (
          <p>
            Se está generando la actualizacion del valor (tarea: #
            {props.actualJob?.jobId})
          </p>
        ) : props.actualJob?.JOB_ESTADO === 23 ? (
          <>
            {" "}
            <p>Se actualizo el valor #{props.actualJob.jobResultado} </p>
          </>
        ) : props.actualJob?.JOB_ESTADO === 24 ? (
          <>
            <p>{props.actualJob.jobResultado} </p>
          </>
        ) : (
          "Estamos trabajando..."
        )}
      </ToastBody>
    </Toast>
  );
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentJob.setActualJob(job),
  updateActualJob: (id) => dispatch.currentJob.updateActualJob(id),
  emptyActualJob: () => dispatch.currentJob.emptyActualJob(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
