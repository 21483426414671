import { makeModel } from "./crudModel";
import { currentJobModel as currentJobModels } from "./currentJobModel";
export const currentJobModel = currentJobModels("currentJob");
export const colaboratorSync = makeModel("colaboratorSync");
export const colaboratorEdit = makeModel("colaboratorEdit");
export const colaboratorRemove = makeModel("colaboratorRemove");
export const audit = makeModel("audit");
export const positions = makeModel("positions");
export const areas = makeModel("areas");
export const domains = makeModel("domains");
export const errorReporting = makeModel("errorReporting");
export const uo = makeModel("uo");
export const uoFolders = makeModel("uoFolders");
export { default as sso } from "./ssoModel";
