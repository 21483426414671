import React from "react";
import JobsToast from "./JobsToast";
import { connect } from "react-redux";
import JobsToastComision from "./JobsToastComision";

const Jobs = (props) => {
  if (!props.actualJob?.jobId) return <></>;
  debugger;
  if (
    props.actualJob?.jobId &&
    props.actualJob?.jobTipo === "GenerarRendicion"
  ) {
    return <JobsToast />;
  }

  if (props.actualJob?.jobId && props.actualJob?.jobTipo === "CambioComision") {
    return <JobsToastComision />;
  }

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentJob.setActualJob(job),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
