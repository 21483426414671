import React from "react";
import logo from "../../../assets/img/logo/autocity.png";
import "../../../assets/scss/components/app-loader.scss";
import "../../../assets/scss/Custom/Loading.scss";
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100 d-flex justify-content-center">
        <div className="d-flex justify-content-center flex-column">
          <img className="logoEmpresa" src={logo} height="150px" alt="logo" />

          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p style={{ background: "#eee", padding: "10px 15px" }}>
            Estamos cargando la información...
          </p>
        </div>
        {/* <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div> */}
      </div>
    );
  }
}

export default SpinnerComponent;
