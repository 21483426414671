export default {
  state: {
    token: null,
    languagesId: 1,
    userProfile: {},
    menu: [],
  },
  reducers: {
    setToken(state, token) {
      return Object.assign({}, { ...state, token: token });
    },
    setLanguage(state, languagesId) {
      return Object.assign({}, { ...state, languagesId: languagesId });
    },
    logOut(state) {
      return { token: null, languagesId: 1, userProfile: {}, menu: [] };
    },
    setUser(state, { token, userProfile, menu }) {
      return Object.assign(
        {},
        { ...state, token: token, userProfile: userProfile, menu: menu }
      );
    },
  },
  effects: {},
};
