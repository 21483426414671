import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import { init } from "@rematch/core";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import createRematchPersist from "@rematch/persist";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import thunk from "redux-thunk";
import "./index.scss";
import "./@fake-db";
import * as models from "./redux";
import customizerReducer from "./redux/reducers/customizer";
import authReducers from "./redux/reducers/auth";
import navbarReducer from "./redux/reducers/navbar/Index";
import Jobs from "./components/Ceek/Jobs";
const LazyApp = lazy(() => import("./App"));

const persistPlugin = createRematchPersist({
  whitelist: ["country", "sso"],
  throttle: 1000,
  version: 1,
});

export const store = init({
  models,
  redux: {
    devtoolOptions: {
      disabled: false, //process.env.REACT_APP_REDUX_DEVTOOLS_DISABLED === "true",
    },
    reducers: {
      customizer: customizerReducer,
      auth: authReducers,
      navbar: navbarReducer,
    },
    middlewares: [thunk],
  },
  plugins: [persistPlugin],
});
// configureDatabase()
const persistor = getPersistor();
// const s = store.getState();
// debugger;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <IntlProviderWrapper>
            <LazyApp />
          </IntlProviderWrapper>
        </Layout>
        <Jobs />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
