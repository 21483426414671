import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const Jobs = (props) => {
  const [visible, setVisible] = React.useState(true);
  const [interval, setInt] = React.useState(null);
  useEffect(() => {
    setInt(
      setInterval(() => props.updateActualJob(props.actualJob.jobId), 2000)
    );
    // debugger;
    return () => clearInterval(interval);
  }, [props.actualJob.jobId]);

  useEffect(() => {
    // debugger;
    if (props.actualJob?.JOB_ESTADO !== 22) {
      clearInterval(interval);
      setVisible(true);
    }
  }, [props.actualJob]);

  if (!props.actualJob?.jobId) return <></>;

  if (!visible) {
    return <></>;
  }

  const close = (
    <button
      type="button"
      onClick={() => {
        // setVisible(false);
      }}
      className="ml-1 close"
    >
      <span>×</span>
    </button>
  );
  const redirect = () => {
    if (props.actualJob?.JOB_ESTADO === 22) return;
    if (props.actualJob?.JOB_ESTADO === 24) {
      window.location.href = "../rendiciones/";
    } else {
      window.location.href =
        "../rendiciones/show/" + props.actualJob?.jobResultado;
    }
  };

  return (
    <Toast className="job-toast ">
      <ToastHeader className="bg-primary" close={close}>
        Generando la rendición
      </ToastHeader>
      <ToastBody onClick={redirect}>
        {props.actualJob?.JOB_ESTADO === 22 ? (
          <p>
            Se está generando la rendición (tarea: #{props.actualJob?.jobId})
          </p>
        ) : props.actualJob?.JOB_ESTADO === 23 ? (
          <>
            {" "}
            <p>Se genero la rendición #{props.actualJob.jobResultado} </p>
            <a
              href={
                "../rendiciones/show/" + props.actualJob.jobResultado
              }
            >
              ir a ver
            </a>
          </>
        ) : props.actualJob?.JOB_ESTADO === 24 ? (
          <>
            <p>
              Falló la generación de la rendición,{" "}
              {props.actualJob.jobResultado}{" "}
            </p>
            <a href={"../rendiciones/"}>ir a ver</a>
          </>
        ) : (
          "Estamos trabajando en la rendición"
        )}
      </ToastBody>
    </Toast>
  );
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentJob.setActualJob(job),
  updateActualJob: (id) => dispatch.currentJob.updateActualJob(id),
  emptyActualJob: () => dispatch.currentJob.emptyActualJob(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
