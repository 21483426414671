import { connect } from "react-redux";
import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ContextLayout } from "../../../utility/context/Layout";
const UserDropdown = (props) => {
  const history = useHistory();
  const goToLogout = () => {
    history.push("/LogOut");
  };

  return (
    <ContextLayout.Consumer>
      {(context) => {
        return (
            <DropdownMenu right style={{ width: "fit-content" }}>
            <div className="text-center">
              <Button
                tag="a"
                color="flat-black"
                className="user-name text-bold-600"
                style={{ whiteSpace: "nowrap" }}
                disabled={true}
              >
                {props.denominacion ? props.denominacion : props.email}
              </Button>
              <Button
                tag="a"
                color="flat-primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={(e) => goToLogout()}
              >
                Cerrar sesión
              </Button>
            </div>
          </DropdownMenu>
        );
      }}
    </ContextLayout.Consumer>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {this.props.denominacion || this.props.email ? (
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.denominacion ? this.props.denominacion : this.props.email}
              </span>
            </div>
            <span data-tour="user">
              <Button outline className="ml-3 button-navbar">Salir</Button>{' '}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
        ): (
          <></>
        )}
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  const { denominacion } = state.sso.userProfile;
  const { email } = state.sso.userProfile;
  return {
    denominacion,
    email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLayout: () => dispatch.customizer.Layout("horizontal"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
